import React from "react";
import {graphql} from "gatsby";
import ResponsiveEmbed from "react-responsive-embed";

import Layout from "../components/layout";
import SEO from "../components/seo";

export const query = graphql `
query ShowreelPageQuery {
    sanityAboutPage {
      showreel {
        url
      }
    }
    sanitySiteSettings {
      socialShareImage {
        ...Image
      }
    }
  }   
`;

const ShowreelPage = ({data}) => (
    <Layout>
        <SEO title="Showreel" image={data.sanitySiteSettings.socialShareImage}/>
        <section id="showreel" className="pt-nav bg-dark">
        <ResponsiveEmbed allowFullScreen src={data.sanityAboutPage.showreel.url}/>
        </section>
    </Layout>
);

export default ShowreelPage;
